import { MenuItem } from "primereact/menuitem";

import { LocaleMessagesType } from "../../hooks/useI18nLocaleFormat";
import APPLICATION_ROUTES, { TabHashesRoute } from "../../utils/routes";

export const breadcrumbsProposalsPageIndex = (
    localize: (id: LocaleMessagesType) => string,
    disabled: boolean = true,
): MenuItem[] => [{ label: localize("page.home.content.title"), disabled, url: APPLICATION_ROUTES.proposals.route }];

export const breadcrumbsProposalPageIndex = (
    localize: (id: LocaleMessagesType) => string,
    id: number,
    tab: TabHashesRoute,
    disabled: boolean = true,
): MenuItem[] => [
    ...breadcrumbsProposalsPageIndex(localize, false),
    {
        label: localize("page.proposal.content.title"),
        disabled,
        url: APPLICATION_ROUTES.proposal.proposalRouteMain(id),
    },
    {
        label: localize(`page.proposal.content.tabs.${tab.tabName}.title` as any),
        disabled,
        url: APPLICATION_ROUTES.proposal.proposalRouteTabName(id, tab),
    },
];

export const breadcrumbsEventsPageIndex = (
    localize: (id: LocaleMessagesType) => string,
    disabled: boolean = true,
): MenuItem[] => [
    {
        label: `${localize("page.events.content.title")}`,
        disabled,
        url: APPLICATION_ROUTES.events.route,
    },
];

export const breadcrumbsEventPageIndex = (
    localize: (id: LocaleMessagesType) => string,
    eventId: number,
    eventName: string,
    disabled: boolean = true,
): MenuItem[] => [
    ...breadcrumbsEventsPageIndex(localize, true),
    {
        label: eventName,
        disabled,
        url: APPLICATION_ROUTES.events.routeToEvent(eventId),
    },
];

export const breadcrumbsSchedulePageIndex = (
    localize: (id: LocaleMessagesType) => string,
    eventId: number,
    eventName: string,
    disabled: boolean = true,
): MenuItem[] => [
    ...breadcrumbsEventPageIndex(localize, eventId, eventName, true),
    {
        label: `${localize("page.schedule.breadcrumb")}`,
        disabled,
        url: APPLICATION_ROUTES.schedule.routeToPage(eventId),
    },
];

export const breadcrumbsScheduleLivePageIndex = (
    localize: (id: LocaleMessagesType) => string,
    eventId: number,
    eventName: string,
    disabled: boolean = true,
): MenuItem[] => [
    ...breadcrumbsEventPageIndex(localize, eventId, eventName, true),
    {
        label: `${localize("page.schedule-live.title")}`,
        disabled,
        url: APPLICATION_ROUTES.schedule_live.routeToPage(eventId),
    },
];

export const breadcrumbsScheduleSupportPageIndex = (
    localize: (id: LocaleMessagesType) => string,
    eventId: number,
    eventName: string,
    disabled: boolean = true,
): MenuItem[] => [
    ...breadcrumbsEventPageIndex(localize, eventId, eventName, true),
    {
        label: `${localize("page.schedule-support.title")}`,
        disabled,
        url: APPLICATION_ROUTES.schedule_support.routeToPage(eventId),
    },
];

export const breadcrumbsEventReportPageIndex = (
    localize: (id: LocaleMessagesType) => string,
    eventId: number,
    eventName: string,
    disabled: boolean = true,
): MenuItem[] => [
    ...breadcrumbsEventPageIndex(localize, eventId, eventName, true),
    {
        label: `${localize("page.eventReport.breadcrumb")}`,
        disabled,
        url: APPLICATION_ROUTES.eventReport.routeToPage(eventId),
    },
];

export const breadcrumbsPublicationsPageIndex = (
    localize: (id: LocaleMessagesType) => string,
    eventId: number,
    eventName: string,
    disabled: boolean = true,
): MenuItem[] => [
    ...breadcrumbsEventPageIndex(localize, eventId, eventName, true),
    {
        label: `${localize("page.publications.breadcrumb")}`,
        disabled,
        url: APPLICATION_ROUTES.publications.routeToPage(eventId),
    },
];

export const breadcrumbsAcitivitiesPageIndex = (
    localize: (id: LocaleMessagesType) => string,
    eventId: number,
    eventName: string,
    disabled: boolean = true,
): MenuItem[] => [
    ...breadcrumbsEventPageIndex(localize, eventId, eventName, true),
    {
        label: `${localize("page.activities.breadcrumb")}`,
        disabled,
        url: APPLICATION_ROUTES.activities.routeToPage(eventId),
    },
];

export const breadcrumbsRatingsPageIndex = (
    localize: (id: LocaleMessagesType) => string,
    eventId: number,
    eventName: string,
    disabled: boolean = true,
): MenuItem[] => [
    ...breadcrumbsEventPageIndex(localize, eventId, eventName, true),
    {
        label: `${localize("page.ratings.breadcrumb")}`,
        disabled,
        url: APPLICATION_ROUTES.ratings.routeToPage(eventId),
    },
];

export const breadcrumbsTeamPageIndex = (
    localize: (id: LocaleMessagesType) => string,
    eventId: number,
    eventName: string,
    disabled: boolean = true,
): MenuItem[] => [
    ...breadcrumbsEventPageIndex(localize, eventId, eventName, true),
    {
        label: `${localize("page.team.breadcrumb")}`,
        disabled,
        url: APPLICATION_ROUTES.publications.routeToPage(eventId),
    },
];
