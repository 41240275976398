import React, { FC } from "react";

import styles from "./ProposalsHome.module.scss";

import { useRouter } from "next/router";
import { Button } from "primereact/button";

import { getCfpUrl } from "../../../../environment";
import { Locale, useI18nLocaleFormat } from "../../../hooks/useI18nLocaleFormat";

interface EmptyProposalListProps {
    isNotSrmUser?: boolean;
}

const EmptyProposalList: FC<EmptyProposalListProps> = (props) => {
    // PROPS
    const { isNotSrmUser } = props;
    // DEPS
    const { localeAs } = useI18nLocaleFormat();
    const router = useRouter();

    return (
        <div className={styles.page__empty_list__container}>
            <div className="p-mb-3">
                {isNotSrmUser
                    ? localeAs("page.home.content.emptyProposalList.isNotSrmUser.text")
                    : localeAs("page.home.content.emptyProposalList.text")}
            </div>
            <a href={getCfpUrl(router.locale as Locale)} className={styles.page__empty_list__container__button}>
                <Button label={localeAs("page.home.content.emptyProposalList.button")} />
            </a>
        </div>
    );
};

export default EmptyProposalList;
