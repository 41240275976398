import { isDevelopmentOrLocalEnvironment } from "../../../../environment";

const OnlyDevelopment = ({ children }) => {
    if (isDevelopmentOrLocalEnvironment) {
        return children;
    }
    return null;
};

export const onlyDevelopmentFunc = (data: any) => {
    if (isDevelopmentOrLocalEnvironment) {
        return data;
    }
    return undefined;
};

// noinspection JSUnusedGlobalSymbols
export default OnlyDevelopment;
