import React, { FC } from "react";

import cn from "classnames";

import { Button, ButtonProps } from "primereact/button";

interface ButtonWithIconProps extends ButtonProps {
    color?: string;
}

const ButtonWithIcon: FC<ButtonWithIconProps> = (props) => {
    const { className, color } = props;
    return (
        <Button
            tooltipOptions={{
                position: "top",
            }}
            {...props}
            className={cn("p-button-rounded p-button-text", className)}
            style={{ color: color }}
        />
    );
};

export default ButtonWithIcon;
