import React, { useRef, useState } from "react";

import { useRouter } from "next/router";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";

import { proposalActions } from "../../../data/srm/models/proposal/proposal.actions";
import { Locale, useI18nLocaleFormat } from "../../../hooks/useI18nLocaleFormat";
import { useIsBrowser } from "../../../hooks/useIsBrowser";
import { EXTERNAL_ROUTES } from "../../../utils/routes";
import ButtonWithIcon from "../../common/Components/Common/ButtonWithIcon";

const ProposalCardMenu = ({ proposal }) => {
    // DEPS
    const { localeAs } = useI18nLocaleFormat();
    const isBrowser = useIsBrowser();
    const router = useRouter();

    // DATA
    const footerMenuRef = useRef();
    const [closeProposalDialogVisible, setCloseProposalDialogVisible] = useState(false);
    const [transferProposalDialogVisible, setTransferProposalDialogVisible] = useState(false);

    // TODO check right status
    if (proposalActions.isClosedProposal(proposal)) {
        return null;
    }

    if (!isBrowser) {
        return null;
    }

    const footerMenuItems: MenuItem[] = [
        {
            label: localeAs("page.home.content.proposalCard.menu.close"),
            command: () => setCloseProposalDialogVisible(true),
        },
        {
            label: localeAs("page.home.content.proposalCard.menu.transfer"),
            command: () => setTransferProposalDialogVisible(true),
        },
        {
            label: localeAs("page.home.content.proposalCard.menu.again"),
            // TODO change to event-version
            command: () => router.push(EXTERNAL_ROUTES.cfp.route(router.locale as Locale)),
        },
    ];

    const dialogsCloseHandler = () => {
        setCloseProposalDialogVisible(false);
        setTransferProposalDialogVisible(false);
    };

    // TODO actions
    const closeProposalDialog = (
        <Dialog
            header={localeAs("page.home.content.closeDialog.title")}
            visible={closeProposalDialogVisible}
            onHide={dialogsCloseHandler}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
        >
            <p>{localeAs("page.home.content.closeDialog.text")}</p>
            <p>
                <Button label={localeAs("page.home.content.closeDialog.OK")} className="p-button-danger" />
                <Button label={localeAs("page.home.content.closeDialog.CANCEL")} className="p-ml-4" />
            </p>
        </Dialog>
    );

    // TODO actions
    const transferProposalDialog = (
        <Dialog
            header={localeAs("page.home.content.transferDialog.title")}
            visible={transferProposalDialogVisible}
            onHide={dialogsCloseHandler}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
        >
            <p>{localeAs("page.home.content.transferDialog.text")}</p>
            <p>
                <Button label={localeAs("page.home.content.transferDialog.OK")} className="p-button-danger" />
                <Button label={localeAs("page.home.content.transferDialog.CANCEL")} className="p-ml-4" />
            </p>
        </Dialog>
    );

    return (
        <>
            <Menu model={footerMenuItems} popup ref={footerMenuRef} />
            <ButtonWithIcon
                icon="pi pi-ellipsis-v"
                className="p-ml-2"
                // @ts-ignore
                onClick={(event) => footerMenuRef?.current?.toggle(event)}
            />
            {closeProposalDialog}
            {transferProposalDialog}
        </>
    );
};

export default ProposalCardMenu;
