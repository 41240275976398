import React, { FC } from "react";

import cn from "classnames";

import { Tag, TagSeverityType } from "primereact/tag";

import { participationActions } from "../../../../data/srm/models/participation/participation.actions";
import { ParticipationConsent } from "../../../../data/srm/models/participation/participation.models";
import { useI18nLocaleFormat } from "../../../../hooks/useI18nLocaleFormat";
import { CommonComponent } from "../common";

interface PublicationConsentTagProps extends CommonComponent {
    participationConsent: ParticipationConsent;
    hideSuccess?: boolean;
}

const PublicationConsentTag: FC<PublicationConsentTagProps> = ({ participationConsent, hideSuccess, className }) => {
    const { localeAs } = useI18nLocaleFormat();

    const publicationConsentAccepted = participationActions.getPublicationConsentForm(participationConsent)?.accepted;

    if (hideSuccess && publicationConsentAccepted) {
        return null;
    }

    const severity: TagSeverityType = publicationConsentAccepted ? "success" : "danger";

    const value =
        publicationConsentAccepted === true
            ? localeAs("domain.proposal.participation.consent.forms.accepted.TRUE")
            : publicationConsentAccepted === false
            ? localeAs("domain.proposal.participation.consent.forms.accepted.FALSE")
            : localeAs("domain.proposal.participation.consent.forms.accepted.UNDEFINDED");

    return <Tag className={cn(className)} severity={severity} value={value} />;
};

export default PublicationConsentTag;
