import React, { FC, useState } from "react";

import cn from "classnames";

import styles from "./ProposalsHome.module.scss";

import { Dialog } from "primereact/dialog";
import { Paginator } from "primereact/paginator";
import { Panel } from "primereact/panel";

import { ProposalEntity } from "../../../data/srm/models/proposal/proposal.models";
import { useI18nLocaleFormat } from "../../../hooks/useI18nLocaleFormat";
import { CommonComponent } from "../../common/Components/common";
import EmptyProposalList from "./EmptyProposalList";
import ProposalCard from "./ProposalCard";

interface ProposalPanelProps extends CommonComponent {
    activeList?: boolean;
    proposalList?: ProposalEntity[];
    isNotSrmUser?: boolean;
}

const MAX_CARDS_COUNT = 12;

const ProposalPanel: FC<ProposalPanelProps> = (props) => {
    // PROPS
    const { activeList, proposalList, className, isNotSrmUser } = props;

    // DEPS
    const { localeAs } = useI18nLocaleFormat();

    // DATA
    const [notAllProposalDialogVisible, setNotAllProposalDialogVisible] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(MAX_CARDS_COUNT);

    const isEmpty = proposalList?.length === 0;
    const isFull = proposalList?.length > MAX_CARDS_COUNT;

    // HANDLERS
    const notAllProposalDialogCloseHandler = () => setNotAllProposalDialogVisible(false);

    const onBasicPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    // RENDERS
    const notAllProposalDialog = (
        <Dialog
            header={localeAs("page.home.content.activeProposals.title.link.text")}
            visible={notAllProposalDialogVisible}
            onHide={notAllProposalDialogCloseHandler}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "50vw" }}
        >
            <p>
                {localeAs("page.home.content.notAllProposalDialog.text")}{" "}
                <a href={`emailto:${localeAs("page.home.content.notAllProposalDialog.text.email")}`}>
                    {localeAs("page.home.content.notAllProposalDialog.text.email")}
                </a>
            </p>
        </Dialog>
    );

    const activePanelTemplate = (options) => {
        const titleClassName = `${options.titleClassName} p-pl-1`;
        const activePanelTemplateClassName = `${options.className} p-jc-start`;

        return (
            <div className={activePanelTemplateClassName}>
                <span className={cn(titleClassName, "p-text-uppercase")}>
                    {localeAs("page.home.content.activeProposals.title")}
                </span>
                <span className="p-link p-ml-2 p-panel-title">
                    <a onClick={() => setNotAllProposalDialogVisible(true)}>
                        {localeAs("page.home.content.activeProposals.title.link.text")}
                    </a>
                </span>
                {notAllProposalDialog}
            </div>
        );
    };

    const renderList = () => {
        const slised = activeList || !isFull ? proposalList : proposalList.slice(first, first + rows);

        return slised?.map((p) => <ProposalCard key={p.id} proposal={p} />);
    };

    const renderCards = () => {
        if (isEmpty) {
            return <EmptyProposalList isNotSrmUser={isNotSrmUser} />;
        }
        return <div className={styles.page__card_list__container}>{renderList()}</div>;
    };

    if (proposalList === undefined) {
        return null;
    }

    if (!activeList && isEmpty) {
        return null;
    }

    if (activeList) {
        return (
            <Panel
                headerTemplate={activePanelTemplate}
                className={cn(className, { [styles.page__panel_empty]: isEmpty })}
            >
                {renderCards()}
            </Panel>
        );
    } else {
        return (
            <Panel header={localeAs("page.home.content.closedProposals.title").toUpperCase()} className={cn(className)}>
                {isFull && (
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={proposalList.length}
                        onPageChange={onBasicPageChange}
                        className="p-mb-2"
                    />
                )}
                {renderCards()}
                {isFull && (
                    <Paginator
                        first={first}
                        rows={rows}
                        totalRecords={proposalList.length}
                        onPageChange={onBasicPageChange}
                        className="p-mt-2"
                    />
                )}
            </Panel>
        );
    }
};

export default ProposalPanel;
